// src/App.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './App.css'

import Navbar from './components/Navbar';

const App = () => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [isLoggedIn, setLoggedIn] = useState(false);

    useEffect(() =>{
        console.log("axios.get(",`${process.env.REACT_APP_API_URL}/dashboard/user`)
        axios.get(`${process.env.REACT_APP_API_URL}/dashboard/user`, { withCredentials: true })
            .then(response => {
                setUser(response.data);
                localStorage.setItem('isAuthenticated', true);
                setLoggedIn(true);
                setError(null);
            })
            .catch(error => {
                // setError(error)
                localStorage.removeItem('isAuthenticated');
                setLoggedIn(false);
            });
    }, []);

    return (<>
        <Navbar user={user} isLoggedIn={isLoggedIn} error={error}/>
    </>);
};

export default App;
