import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Home from '../pages/Home';   // Import your Home component
import ProtectedRoute from './ProtectedRoute'; // Import your ProtectedRoute component
import Dashboard from '../pages/Dashboard'; // Import your Dashboard component

import './Navbar.css';

const Navbar = ({ user, isLoggedIn, error }) => {
    console.log("react app apî url", process.env.REACT_APP_API_URL)
    const imageSrc = user?.id && user?.avatar ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.webp` : "";
    return (
        <Router>
            <div className="navbar">
                <img src='./favicon.ico' className='navbar-img' height={"75px"} alt="Logo Coalition" />
                <nav className="navbar-nav">
                    {isLoggedIn ? (
                        <>
                            <NavLink to="/" className={({ isActive }) => isActive ? "active" : ""}>Home</NavLink>
                            <NavLink to="/dashboard" className={({ isActive }) => isActive ? "active" : ""}>Dashboard</NavLink>
                            <a href={`${process.env.REACT_APP_API_URL}/auth/logout`}>Logout</a>
                            <span className="user-info">
                                {user.global_name || user.username}
                                <img src={imageSrc || './favicon.ico'} alt="Profile" className="avatar" />
                            </span>
                        </>
                    ) : (
                        <a href={`${process.env.REACT_APP_API_URL}/auth/discord`} className='discord-link'>
                            <span>Connection Discord</span>
                            <img src="/discord-icon.svg" alt="Discord Icon" />
                        </a>
                    )}
                </nav>
            </div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route 
                    path="/dashboard" 
                    element={
                        <ProtectedRoute>
                            <Dashboard user={user} />
                        </ProtectedRoute>
                    } 
                />
            </Routes>
        </Router>
    );
};

export default Navbar;
